import React from 'react'

const Error = () => {
  return (
    <div className='circularLoader'>
        <p style={{textAlign:'center', fontSize:30}}> 
Oops!! Data couldn't be loaded.
          </p>
     </div>
  )
}

export default Error;