import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Button from "./Button";
import axios from "axios";
import Loader from "./Loader";
import Error from "./Error";
import { Alert, Snackbar } from "@mui/material";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const dataReturned = useQuery({
    queryKey: ["contactData"],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/about-us/?website=Individual`
        )
        .then((res) => res.data),
  });
  const mutation = useMutation({
    mutationFn: (newRequest) => {
      console.log(newRequest);
      return axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/contact-us/?website=Individual`,
        newRequest
      );
    },
  });

  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalContact = {
      email: email,
      full_name: name,
      website: "individual",
      message: message,
    };

    mutation.mutate(finalContact, {
      onSuccess: (data, variables, context) => {
        clearState();
        setSuccessMessage("Message successfully recorded.");
        setOpen(true);
      },
    });
  };

  if (dataReturned.isLoading) {
    return <Loader />;
  }

  if (dataReturned.isError) {
    return <Error />;
  }

  return (
    <div>
      <div id="contact">
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiAlert-message ": {
              fontSize: "1.5rem",
            },
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert icon severity="success" sx={{ width: "100%" }}>
            {successMessage}
          </Alert>
        </Snackbar>

        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <Button mutation={mutation} />
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {dataReturned.data[0].address}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {dataReturned.data[0].contact_number}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {dataReturned.data[0].email}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Ibtidaa Softwares.
            <a
              href="https://www.youtube.com/watch?v=FbtWBrIR3nQ"
              rel="nofollow"
            >
              <br></br>
              Ibtidaa
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
