import { CircularProgress } from "@mui/material";
import { PacmanLoader, PropagateLoader, PuffLoader, SyncLoader,HashLoader } from "react-spinners";

function Loader() {
    return (
    <div className="circularLoader">
        {/* <PuffLoader color="#6372ff"/> */}
        <HashLoader color="#6372ff"/>
        {/* <PropagateLoader color="#6372ff"/> */}
        {/* <SyncLoader color="#6372ff"/> */}
    </div>
    )
}
export default Loader;
