import React from 'react'
import { Navigation } from './components/Navigation'
import { Outlet } from 'react-router-dom'

const NavLayout = () => {
  return (
    <>
    <div>
      <Navigation />
    </div>
    <Outlet/>

    </>
   
  )
}

export default NavLayout;