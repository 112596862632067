import React from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import Logo from "./Logo";
import { useEffect } from "react";

export const Navigation = (props) => {
  useEffect(() => {
    // jQuery to collapse the navbar when a link is clicked
    const $ = window.jQuery;
    $('.navbar-nav>li>a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        <NavLink to="/">{Logo}</NavLink>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
           <li >
            <NavLink to="/about">About</NavLink>
            </li>
            <li>
            <NavLink to="/services">Services</NavLink>
            </li>
            <li>
       <NavLink to="/gallery">Gallery</NavLink>
            </li>
            <li>
            <NavLink to="/testimonials">Testimonials</NavLink>
            </li>
            <li>
            <NavLink to="/team">Team</NavLink>
            </li>
            <li>
            <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
