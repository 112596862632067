import React from "react";
import { Link } from "react-router-dom";

export const Header = (props) => {
  return (
    <div className="intro">
      <div className="introDiv">
        <div className="introTitle" style={{ textAlign: "center" }}>
          {props.data ? props.data.title : "Loading"}
        </div>
        <div className="introDescription" style={{ textAlign: "center" }}>
          An initiative by RP Srijan & Sangita Timilsina
        </div>

        <div>
          <Link to="/services" className="btn btn-custom btn-lg page-scroll">
            Our Services
          </Link>
        </div>
      </div>
    </div>
  );
};
